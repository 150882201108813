.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}

.MuiInput-underline.Mui-disabled:before {
  border: none !important;
}

.custom-file-input::before {
  content: "Select some files";
  display: inline-block;
  border: 1px solid #999;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 10pt;
}

.custom-file-input:hover::before {
  border-color: black;
}

.custom-file-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

a {
  text-decoration: none;
  color: #104f67;
  font-weight: 700;
}

.MuiDataGrid-root .MuiDataGrid-main {
  display: flex !important;
  position: relative !important;
  flex-grow: 1 !important;
  flex-direction: column !important;
  min-width: 1000px !important;
  max-width: 1650px !important;
}

.PrivateRadioButtonIcon-root-41 {
  color: #104f67;
}

.MuiDataGrid-root .MuiDataGrid-cell--textLeft {
  text-align: right !important;
}

.MuiInputLabel-formControl {
  left: 80% !important;
  right: 0 !important;
}

.MuiDataGrid-root .MuiDataGrid-footerContainer {
  justify-content: flex-start !important;
}
.MuiTypography-root .MuiTablePagination-caption {
  display: none !important;
}

.parserMore h4 {
  margin-bottom: 0;
  font-weight: 700;
  margin-top: 5px !important;
}

.parserMore p strong {
  margin-top: 5px !important;
  margin-bottom: 10px !important;
  display: block;
  color: #000;
}

.parserMore p {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  color: #4e5459;
}

.parserMore ul {
  list-style-type: initial;
  list-style-position: outside;
  color: #4e5459;
  padding-right: 25px;
  font-size: 14px;
}

.parserMore ol {
  color: #4e5459;
  padding-right: 20px;
  font-size: 14px;
}

@media (min-width: 0px) {
  .parserMore h4 {
    font-size: 14px;
  }

  .parserMore p {
    font-size: 14px;
  }

  .parserMore ul {
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  .parserMore h4 {
    font-size: 16px !important;
    color: #4e5459 !important;
    width: 100%;
  }

  .parserMore p strong {
    color: #4e5459 !important;
    width: 100%;
  }

  .parserMore p {
    font-size: 14px !important;
    width: 100%;
  }

  .parserMore ul {
    font-size: 14px !important;
  }
}

.MuiDataGrid-row div:nth-of-type(2) {
  direction: ltr;
}
